/* created with the help of  http://mcg.mbitson.com/ */

/* For use in src/lib/core/theming/_palette.scss */
$md-leerlinq-purple: (
  50 : #f1e4f0,
  100 : #ddbbda,
  200 : #c68ec1,
  300 : #af61a8,
  400 : #9d3f95,
  500 : #8c1d82,
  600 : #841a7a,
  700 : #79156f,
  800 : #6f1165,
  900 : #5c0a52,
  A100 : #ff90f0,
  A200 : #ff5de9,
  A400 : #ff2ae2,
  A700 : #ff10de,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #ffffff,
    A700 : #ffffff,
  )
);

/* For use in src/lib/core/theming/_palette.scss */
$md-leerlinq-blue: (
  50 : #e0f0f8,
  100 : #b3daef,
  200 : #80c2e4,
  300 : #4daad9,
  400 : #2697d0,
  500 : #0085c8,
  600 : #007dc2,
  700 : #0072bb,
  800 : #0068b4,
  900 : #0055a7,
  A100 : #d1e5ff,
  A200 : #9ec9ff,
  A400 : #6badff,
  A700 : #529eff,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

/* For use in src/lib/core/theming/_palette.scss */
$md-leerlinq-green: (
  50 : #e7f5e7,
  100 : #c4e6c2,
  200 : #9dd59a,
  300 : #75c472,
  400 : #58b753,
  500 : #3aaa35,
  600 : #34a330,
  700 : #2c9928,
  800 : #259022,
  900 : #187f16,
  A100 : #b7ffb6,
  A200 : #85ff83,
  A400 : #53ff50,
  A700 : #3aff36,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

/* For use in src/lib/core/theming/_palette.scss */
$md-leerlinq-pink: (
  50 : #fce0f0,
  100 : #f8b3d8,
  200 : #f380bf,
  300 : #ee4da5,
  400 : #ea2691,
  500 : #e6007e,
  600 : #e30076,
  700 : #df006b,
  800 : #db0061,
  900 : #d5004e,
  A100 : #fffcfd,
  A200 : #ffc9d9,
  A400 : #ff96b6,
  A700 : #ff7da4,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

/* For use in src/lib/core/theming/_palette.scss */
$md-leerlinq-orange: (
  50 : #fef6e7,
  100 : #fee9c2,
  200 : #fddb99,
  300 : #fccc70,
  400 : #fbc152,
  500 : #fab633,
  600 : #f9af2e,
  700 : #f9a627,
  800 : #f89e20,
  900 : #f68e14,
  A100 : #ffffff,
  A200 : #fff9f2,
  A400 : #ffe0bf,
  A700 : #ffd3a6,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #000000,
    600 : #000000,
    700 : #000000,
    800 : #000000,
    900 : #000000,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

