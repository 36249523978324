// copied from https://github.com/angular/components/blob/2f9a59a24c0464cbed7f54cbeb5cba73e6007715/integration/ng-update-v13/src/styles.scss

// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;

// Plus imports for other components in your app.
@import 'src/styles/leerlinq-palettes';

@include mat.core();

// leerlinq color palettes
$palette-leerlinq-green: mat.m2-define-palette($md-leerlinq-green);
$palette-leerlinq-pink: mat.m2-define-palette($md-leerlinq-pink);
$palette-leerlinq-blue: mat.m2-define-palette($md-leerlinq-blue);
$palette-leerlinq-purple: mat.m2-define-palette($md-leerlinq-purple);
$palette-leerlinq-orange: mat.m2-define-palette($md-leerlinq-orange);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$palette-leerlinq-theme: mat.m2-define-light-theme((
  color: (
    primary: $palette-leerlinq-blue,
    accent: $palette-leerlinq-pink,
    warn: $palette-leerlinq-pink,
  )
));

$palette-leerlinq-btn-theme: mat.m2-define-light-theme((
  color: (
    primary: $palette-leerlinq-blue,
    accent: $palette-leerlinq-purple,
    warn: $palette-leerlinq-pink,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.

@include mat.all-component-themes($palette-leerlinq-theme);
@include mat.button-color($palette-leerlinq-btn-theme);
@include mat.fab-color($palette-leerlinq-btn-theme);
@include mat.icon-button-color($palette-leerlinq-btn-theme);

// leerlinq colors
$blue-50: mat.m2-get-color-from-palette($palette-leerlinq-blue, 50);
$green-50: mat.m2-get-color-from-palette($palette-leerlinq-green, 50);
$green-800: mat.m2-get-color-from-palette($palette-leerlinq-green, 800);
$pink-800: mat.m2-get-color-from-palette($palette-leerlinq-pink, 800);
$purple-800: mat.m2-get-color-from-palette($palette-leerlinq-purple, 800);
$pink-contrast-800: mat.m2-get-contrast-color-from-palette($palette-leerlinq-pink, 800);
$orange-800: mat.m2-get-color-from-palette($palette-leerlinq-orange, 800);

