/* You can add global styles to this file, and also import other style files */
@import "material-icons/iconfont/material-icons";
@import "styles/leerlinq.theme";
@import "@material/layout-grid/mdc-layout-grid";
@import "jsoneditor/dist/jsoneditor.min.css";

:root {
  --mdc-layout-grid-gutter-desktop: 10px;
  --mdc-layout-grid-margin-desktop: 0;
}

html,
body {
  height: 100%;
}

body {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  margin: 0;
}

[role="main"] {
  background-color: $blue-50;
  min-height: 100%;
  padding: 100px 20px 20px; // Space for fixed navbar
}

[role="main"]::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.05;
  background-image: url("/assets/images/lqvorm.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.mat-mdc-button-base {
  margin: 8px 8px 8px 0;
}

tr.detail-row:hover {
  background-color: #f1e4f0 !important;
  cursor: pointer;
}

tr.non-clickable-row:hover {
  background-color: #f1e4f0 !important;
}

.full-width {
  width: 100%;
}

.mdc-layout-grid__inner {
  align-items: center;
}

.button-container {
  display: flex;
}
